<template>
  <div>
    <navBar />
    <div class="box">
      <div class="safe2">
        <el-tabs v-model="activeName">
          <el-tab-pane label="基础信息" name="first">
            <div class="el-tabs-content">
              <div class="formBox">
                <el-form
                  label-position="left"
                  label-width="120px"
                  :model="form"
                >
                  <el-row>
                    <el-form-item label="姓名">
                      <el-col :span="6">
                        <!-- <el-input
                          v-model="form.student_name"
                          v-if="showInput"
                          maxlength="8"
                          show-word-limit
                        ></el-input> -->
                        <span>{{ form.student_name }}</span>
                      </el-col>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="学校">
                      <el-col :span="6">
                        <el-input
                          v-model="form.school"
                          v-if="showInput"
                        ></el-input>
                        <span v-else>{{ form.school }}</span>
                      </el-col>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="学历">
                      <el-col :span="6" v-if="showInput">
                        <el-select
                          v-model="form.education"
                          placeholder="请选择"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-col>
                      <span v-else>{{ form.education }}</span>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="密码">
                      <el-col :span="6">
                        <el-input
                          v-model="form.password"
                          v-if="showInput"
                          placeholder="密码应为字母，数字组成的6-18位字符串"
                        ></el-input>
                        <span v-else>******</span>
                      </el-col>
                    </el-form-item>
                  </el-row>
                </el-form>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="infoBox">
          <p @click="showInputFn" v-if="!showInput">编辑</p>
          <div v-else>
            <el-button size="small" @click="showInput = !showInput"
              >取 消</el-button
            >
            <el-button size="small" type="primary" @click="yesBtn"
              >保 存</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navBar from "@/components/navBar/navBar.vue";
import { editUser, editUserGet } from "@/api/order.js";
export default {
  data() {
    return {
      activeName: "first",
      form: {
        student_name: "",
        school: "",
        major: "",
        education: "高等教育",
        password: "",
      },
      options2: [],
      options: [
        {
          value: "高等教育",
          label: "高等教育",
        },
        {
          value: "高职教育",
          label: "高职教育",
        },
        {
          value: "中等教育",
          label: "中等教育",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
      showInput: false,
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      editUserGet({ dir: "1" }).then((res) => {
        this.form = res.data;
      });
    },
    showInputFn() {
      this.showInput = true;
    },
    yesBtn() {
      if(!this.form.password){
        this.$message.error("请输入密码");
        return;
      }
      editUser({ ...this.form }).then((res) => {
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.getInfo();
        this.showInput = false;
      });
    },
  },
  components: { navBar },
};
</script>
<style lang="scss" scoped>
.safe2 {
  width: 83vw;
  margin: auto;
  padding: 30px 20px;
  box-sizing: border-box;
  background: #f8f9fa;
  position: relative;
  .infoBox {
    position: absolute;
    right: 20px;
    top: 50px;
    font-size: 18px;
    cursor: pointer;
    color: #4285f4;
  }
}

::v-deep .el-tabs__item {
  padding: 0px 20px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 500;
  color: #409eff;
}
::v-deep .el-form {
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #dadce0;
  background-color: #fff;
  box-shadow: inset 4px 0 0 0 #4285f4;
  padding-left: 4px;
  box-sizing: border-box;
}
::v-deep .el-form-item {
  margin-bottom: 0;
  padding: 12px 0px 12px 40px;
  border-bottom: 1px solid #dadce0;
  box-sizing: border-box;
}
::v-deep .el-form-item__content,
::v-deep .el-form-item__label {
  font-size: 20px;
}
</style>
