<template>
  <div>
    <div class="top">
      <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <!-- <el-breadcrumb-item v-for="item in list" :key="item.name">{{
            item.name
          }}</el-breadcrumb-item> -->
          <el-breadcrumb-item :to="{ path: '/newIndex' }"
            >我的课程</el-breadcrumb-item
          >
          <el-breadcrumb-item
            v-if="$route.name == 'detail' || $route.name == 'homeSubmit'"
            >作业详情</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="$route.name == 'user'"
            >用户设置</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="$route.name == 'classInfo'"
            >资料详情</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="$route.name == 'homeWork'"
            >作业详情</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <p class="centerTxt">
        <marquee v-if="look_num">
          欢迎{{ userInfo.student_name }}同学加入{{
            userInfo.grade_name
          }}班，视频进度{{
            Math.round((look_num / section_num) * 100)
          }}%，作业进度{{ stu_job_submit_bfb }}%，再接再厉，勇攀高峰！
        </marquee>
        <!-- 已超{{
            look_num_bfb
          }}%同学， -->
      </p>

      <div class="rigBox">
        <el-button
          plain
          round
          size="small"
          @click="toOpenAi"
          v-if="userInfo.is_chatgpt == 2"
          >课堂猫大模型</el-button
        >
        <!-- <el-button plain round size="small" @click="toOnlineCode"
          >在线编程</el-button
        > -->
        <el-dropdown trigger="click" @command="changeItem">
          <img
            src="../../assets/userCenter.png"
            class="el-dropdown-link userImg"
            alt=""
          />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-setting" command="1"
              >个人设置</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-c-scale-to-original" command="3"
              >我的积分:{{ userInfo.integral_data }}</el-dropdown-item
            >

            <el-dropdown-item icon="el-icon-s-promotion" command="2"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <integrationDialog ref="integrationDialog" />
  </div>
</template>
<script>
import { outLogin } from "@/api/order.js";
import integrationDialog from "@/components/integrationDialog.vue";
export default {
  data() {
    return {
      titleName: "",
      list: [
        {
          name: "我的课程",
          path: "newIndex",
        },
        {
          name: "用户设置",
          path: "user",
        },
        {
          name: "课程介绍",
          path: "classInfo",
        },
        {
          name: "作业",
          path: "homeWork",
        },
      ],
      showUser: false,
      showStudy: false,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      look_num: "",
      section_num: "",
      look_num_bfb: "",
      stu_job_submit_bfb: "",
    };
  },
  created() {
    let timer = setInterval(() => {
      if (this.look_num_bfb) {
        clearInterval(timer);
      } else {
        this.look_num_bfb = localStorage.getItem("look_num_bfb");
        this.section_num = localStorage.getItem("section_num");
        this.look_num = localStorage.getItem("look_num");
        this.look_num_bfb = localStorage.getItem("look_num_bfb");
        this.stu_job_submit_bfb = localStorage.getItem("stu_job_submit_bfb");
      }
    }, 100);
  },
  components: {
    integrationDialog,
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name == "user") {
          this.list[1].name = "用户设置";
          this.showUser = true;
          this.showStudy = false;
        }
        if (to.name == "classStudy") {
          this.list[1].name = "我的课程";
          this.showUser = false;
          this.showStudy = false;
        }
        if (to.name == "studyDetail") {
          this.list[1].name = "资料详情";
          this.showStudy = true;
          this.showUser = false;
        }
        if (to.name == "homeWork") {
          this.list[1].name = "作业";
        }
        if (to.name == "classInfo") {
          this.list[1].name = "课程介绍";
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    toOnlineCode() {
      const routeUrl = this.$router.resolve({
        name: "onlineCode",
      });
      window.open(routeUrl.href, "_blank");
    },
    toOpenAi() {
      // const routeUrl = this.$router.resolve({
      //   name: "openAi",
      //   query: { token: localStorage.getItem("token") },
      // });
      // window.open(routeUrl.href, "_blank");
      window.open("https://chat.kt-mao.com/");
    },
    changeItem(id) {
      if (id == 1 && this.$route.path.split("/")[1] != "user") {
        this.$router.push({ name: "user" });
        this.list[1].name = "用户设置";
      }
      if (id == 2) {
        this.$confirm("确定退出吗")
          .then((_) => {
            outLogin({ dir: "1" }).then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              localStorage.clear();
              this.$router.push({ name: "login" });
            });
          })
          .catch((_) => {});
      }
      if (id == 3) {
        this.$refs.integrationDialog.show();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  position: fixed;
  background-color: #fff;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 28px;
  min-width: 900px;
  display: flex;
  // flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 0 0 #dfdfdf;
  img {
    max-height: 38px;
  }
  .centerTxt {
    color: #4285f4;
    cursor: pointer;

    font-size: 22px;
    height: 70px;
    line-height: 70px;
    // border-bottom: 3px solid #4285f4;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
  }
  .rigBox {
    display: flex;
    align-items: center;
  }
}
::v-deep .el-dropdown {
  .userImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    margin: 0 10px;
  }
}
::v-deep .el-breadcrumb {
  font-size: 19px;
}
::v-deep .el-breadcrumb__inner,
::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #000;
  cursor: pointer !important;
}
</style>
